import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3cc415a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "pageList",
  class: "HdxxList"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ListEngine = _resolveComponent("ListEngine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListEngine, {
      pageList: _ctx.pageList,
      ref: "pageListRef",
      formatPageInfo: _ctx.formatPageInfo
    }, {
      queryParams: _withCtx(() => [
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("单证号：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[0] || (_cache[0] = e => _ctx.pageList.queryParam.dzh = _ctx.valid(e)),
                  placeholder: "请输入单证号",
                  modelValue: _ctx.pageList.queryParam.dzh,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageList.queryParam.dzh) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("启票编码：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[2] || (_cache[2] = e => _ctx.pageList.queryParam.qpbm = _ctx.valid(e)),
                  placeholder: "请输入启票编码",
                  modelValue: _ctx.pageList.queryParam.qpbm,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pageList.queryParam.qpbm) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      extendSlot: _withCtx(() => []),
      tbCols: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "F_DZH",
          label: "单证号",
          sortable: "true"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_DDH",
          label: "启票编码",
          sortable: "true"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_CODE",
          label: "PI编码"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_FHR_GSMC",
          label: "发货人公司名称"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_SHR_GSMC",
          label: "收货人公司名称"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_TZR_GSMC",
          label: "通知人公司名称"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_FLAG",
          label: "是否提交"
        })
      ]),
      _: 1
    }, 8, ["pageList", "formatPageInfo"])
  ]))
}